import React from 'react'
import { FaPause, FaPlay, FaStop } from 'react-icons/fa6'

const TrialRecordingControls = ({ isPaused, onPause, onResume, onStop }) => {
  return (
    <div className='flex justify-center gap-4 mt-8'>
      {!isPaused ? (
        <button
          onClick={onPause}
          className='bg-orange-600 text-white px-4 py-2 rounded-lg flex items-center gap-2 hover:bg-orange-500 transition-transform transform hover:translate-y-[-1px]'
        >
          <FaPause />
          Pause
        </button>
      ) : (
        <button
          onClick={onResume}
          className='bg-green-600 text-white px-4 py-2 rounded-lg flex items-center gap-2 hover:bg-green-500 transition-transform transform hover:translate-y-[-1px]'
        >
          <FaPlay />
          Resume
        </button>
      )}
      <button
        onClick={onStop}
        className='bg-red-600 text-white px-4 py-2 rounded-lg flex items-center gap-2 hover:bg-red-500 transition-transform transform hover:translate-y-[-1px]'
      >
        <FaStop />
        Stop Recording
      </button>
    </div>
  )
}

export default TrialRecordingControls
