import { useRef, useCallback } from 'react'

export const useTrialMediaRecorder = ({
  onStart,
  onStop,
  onPause,
  onResume,
  // limitStats,
}) => {
  const mediaRecorder = useRef(null)
  const chunks = useRef([])
  const stream = useRef(null)

  const startRecording = useCallback(async () => {
    try {
      stream.current = await navigator.mediaDevices.getUserMedia({
        audio: true,
      })
      mediaRecorder.current = new MediaRecorder(stream.current)

      mediaRecorder.current.ondataavailable = (e) => {
        chunks.current.push(e.data)
      }

      mediaRecorder.current.onstop = () => {
        const blob = new Blob(chunks.current, { type: 'audio/wav' })
        chunks.current = []

        // Stop all tracks
        if (stream.current) {
          stream.current.getTracks().forEach((track) => track.stop())
        }

        onStop?.(blob)
      }

      mediaRecorder.current.start()
      onStart?.()
    } catch (error) {
      console.error('Error starting recording:', error)
      throw new Error(
        'Failed to start recording. Please check your microphone permissions.'
      )
    }
  }, [onStart, onStop])

  const stopRecording = useCallback(() => {
    if (mediaRecorder.current?.state !== 'inactive') {
      mediaRecorder.current?.stop()
    }
  }, [])

  const pauseRecording = useCallback(() => {
    if (mediaRecorder.current?.state === 'recording') {
      mediaRecorder.current.pause()
      onPause?.()
    }
  }, [onPause])

  const resumeRecording = useCallback(() => {
    if (mediaRecorder.current?.state === 'paused') {
      mediaRecorder.current.resume()
      onResume?.()
    }
  }, [onResume])

  return {
    startRecording,
    stopRecording,
    pauseRecording,
    resumeRecording,
  }
}
