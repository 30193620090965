import React from 'react'
import { useNavigate } from 'react-router-dom'

const PlanNotification = ({
  userPlan,
  remainingMinutes = 0,
  resetDate,
  closeNotification,
  pendingTranscription,
}) => {
  const navigate = useNavigate()
  const roundedRemainingMinutes = Math.floor(remainingMinutes)

  // Calculate days until reset
  const calculateDaysUntilReset = (resetDateString) => {
    if (!resetDateString) {
      console.error('resetDate is undefined or empty:', resetDateString)
      return 'N/A'
    }

    try {
      const resetDateTime = new Date(resetDateString)

      if (isNaN(resetDateTime.getTime())) {
        console.error('Invalid resetDate format:', resetDateString)
        return 'N/A'
      }

      // Get current date at start of day in UTC
      const now = new Date()
      const currentDate = new Date(
        Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate())
      )

      // Get reset date at start of day in UTC
      const nextReset = new Date(resetDateTime)
      // If the reset date is in the past, add one year
      if (nextReset < now) {
        nextReset.setUTCFullYear(nextReset.getUTCFullYear() + 1)
      }

      const nextResetDate = new Date(
        Date.UTC(
          nextReset.getUTCFullYear(),
          nextReset.getUTCMonth(),
          nextReset.getUTCDate()
        )
      )

      const timeDifference = nextResetDate - currentDate
      const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24))

      return daysDifference
    } catch (error) {
      console.error('Error calculating days until reset:', error)
      return 'N/A'
    }
  }

  const daysUntilReset = calculateDaysUntilReset(resetDate)

  return (
    <div className='relative'>
      <div
        className='bg-cover bg-no-repeat rounded-lg mb-8'
        style={{
          backgroundImage: `url('/assets/images/dashboardnotificationdivbgimage.png')`,
        }}
      >
        {/* Main content container */}
        <div className='p-4 flex flex-col space-y-4 lg:space-y-0 lg:flex-row lg:justify-between lg:items-start'>
          {/* Left side: Plan info */}
          <div className='flex items-start space-x-2'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
            >
              {/* SVG Paths */}
            </svg>
            <div className='flex flex-col'>
              <p className='text-[#EF4444] font-bold text-sm'>
                You are currently on the {userPlan} plan.
              </p>
              <p className='text-[#171717] text-sm'>
                You have <strong>{roundedRemainingMinutes} minutes</strong>{' '}
                remaining this month. Your plan resets in{' '}
                <strong>{daysUntilReset} days</strong>.
              </p>
              {userPlan === 'Free' && (
                <p className='text-[#171717] text-sm mt-1'>
                  Consider upgrading for more minutes and additional features.
                </p>
              )}
            </div>
          </div>

          {/* Right side: Buttons */}
          <div className='flex items-center space-x-2'>
            {userPlan === 'Free' && (
              <button
                onClick={() => navigate('/plans')}
                className='bg-[#2C3E50] text-white px-4 py-2 rounded-md whitespace-nowrap'
                style={{ padding: '9px 16px' }}
              >
                Upgrade Plan
              </button>
            )}
            <button
              onClick={closeNotification}
              className='text-[#32363E] hover:text-gray-700'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
              >
                <path
                  d='M6 18L18 6M6 6L18 18'
                  stroke='#32363E'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </button>
          </div>
        </div>

        {/* Pending transcription notification */}
        {pendingTranscription && (
          <div className='px-4 pb-4'>
            <div className='bg-blue-50 border border-blue-200 rounded-md p-3'>
              <div className='flex flex-col sm:flex-row sm:items-center sm:justify-between space-y-2 sm:space-y-0'>
                <p className='text-blue-700 text-sm'>
                  You have a pending transcription from your trial recording!
                </p>
                <button
                  onClick={() => navigate('/upload-audio?pending=true')}
                  className='inline-flex items-center justify-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors duration-200 text-sm'
                >
                  Process Now
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default PlanNotification
