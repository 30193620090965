import React, { useEffect, useRef } from 'react'

const TrialRecordingTimer = ({
  recordingTime,
  setRecordingTime,
  isPaused,
  limitStats,
  stopRecording,
}) => {
  const timerRef = useRef(null)

  useEffect(() => {
    if (recordingTime >= limitStats.remainingTime) {
      stopRecording()
    }
  }, [recordingTime, limitStats.remainingTime, stopRecording])

  useEffect(() => {
    if (!isPaused) {
      timerRef.current = setInterval(() => {
        setRecordingTime((prev) => prev + 1)
      }, 1000)
    } else {
      clearInterval(timerRef.current)
    }

    return () => clearInterval(timerRef.current)
  }, [isPaused, setRecordingTime])

  const formatTime = (seconds) => {
    const h = Math.floor(seconds / 3600)
    const m = Math.floor((seconds % 3600) / 60)
    const s = seconds % 60
    return `${h.toString().padStart(2, '0')}:${m
      .toString()
      .padStart(2, '0')}:${s.toString().padStart(2, '0')}`
  }

  return (
    <>
      <div className='flex items-center justify-center gap-2 mb-4'>
        <div
          className={`pulse w-3 h-3 ${
            isPaused ? 'bg-red-600 animate-pulse' : 'bg-[#d97706]'
          } rounded-full`}
        ></div>
        <span className='text-gray-700 font-semibold'>
          {isPaused ? 'Recording Paused' : 'Recording in Progress'}
        </span>
      </div>

      <div className='bg-gray-100 rounded-lg p-6 text-center relative mb-6'>
        <div className='text-3xl font-mono font-bold text-blue-600 mb-2'>
          {formatTime(recordingTime)}
        </div>
        <div
          className={`absolute top-0 left-0 right-0 bottom-0 bg-white bg-opacity-80 rounded-lg ${
            isPaused ? 'flex' : 'hidden'
          } items-center justify-center text-xl text-orange-600 font-semibold`}
        >
          PAUSED
        </div>
        <div className='text-sm text-gray-500 mt-2'>
          Time remaining:{' '}
          <span className='font-medium'>
            {formatTime(limitStats.remainingTime - recordingTime)}
          </span>
        </div>
      </div>
    </>
  )
}

export default TrialRecordingTimer
