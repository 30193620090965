// src/components/ProtectedRoute.js
import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import useAuth from '../hooks/useAuth'
import axios from 'axios'

const ProtectedRoute = ({ children }) => {
  const { user, setUser } = useAuth()
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  const token = localStorage.getItem('reekapToken')
  useEffect(() => {
    const API_BASE_URL = process.env.REACT_APP_API_URL
    const getUser = async () => {
      if (token) {
        setLoading(true)
        try {
          const response = await axios(`${API_BASE_URL}/api/auth/user`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          const userData = response.data
          setUser({ ...userData.user, token })
        } catch (error) {
          navigate('/login')
        }
      } else {
        navigate('/login')
      }
      setLoading(false)
    }
    getUser()
  }, [token, navigate, setUser])

  if (loading) {
    return (
      <div className='flex justify-center items-center h-screen w-screen'>
        <div className='loading'></div>
      </div>
    )
  }

  if (!user) {
    return <Navigate to='/login' />
  }
  if (!user.isEmailVerified) {
    return <Navigate to='/verify-email' />
  }
  // if (!user.isPhoneVerified) {
  //   return <Navigate to='/verify-phone' />
  // }

  return children
}

export default ProtectedRoute
