// components/TrialRecordingPage.jsx
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useAudioProcessor } from '../hooks/useAudioProcessor'

import Modal from '../components/Modal'
import LoadingSpinner from '../components/LoadingSpinner'
import { FaMicrophone, FaWaveSquare } from 'react-icons/fa6'
import { HiSparkles } from 'react-icons/hi2'
import TrialRecordingTimer from '../components/TrialRecording/TrialRecordingTimer'
import TrialRecordingControls from '../components/TrialRecording/TrialRecordingControls'
import { useTrialMediaRecorder } from '../hooks/useTrialMediaRecorder'
import { toast } from 'sonner'

// const TrialRecordingPage = () => {
//   return (
//     <RecordingProvider>
//       <TrialRecordingContent />
//     </RecordingProvider>
//   )
// }

const TrialRecordingPage = () => {
  const navigate = useNavigate()
  const [isRecording, setIsRecording] = useState(false)
  const [isPaused, setIsPaused] = useState(false)
  const [recordingTime, setRecordingTime] = useState(0)
  const [isFinishedRecording, setIsFinishedRecording] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [recordedBlob, setRecordedBlob] = useState(null)

  // Trial specific limits
  const trialLimitStats = {
    allowedLimit: 1800,
    currentPeriodUsage: 0,
    remainingTime: 1800,
  }

  const processAudio = useAudioProcessor()

  const { startRecording, stopRecording, pauseRecording, resumeRecording } =
    useTrialMediaRecorder({
      onStart: () => {
        setIsRecording(true)
        setIsPaused(false)
      },
      onStop: (blob) => {
        setRecordedBlob(blob)
        setIsRecording(false)
        setIsFinishedRecording(true)
        setShowModal(true)
      },
      onPause: () => setIsPaused(true),
      onResume: () => setIsPaused(false),
      limitStats: trialLimitStats,
    })

  const handleUploadAndRedirect = async () => {
    setIsLoading(true)
    try {
      const audioBlob = await processAudio(recordedBlob)
      const formData = new FormData()
      formData.append('file', audioBlob)

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/trial/trial_upload`,
        formData
      )

      localStorage.setItem('tempUserId', response.data.data.tempUserId)
      navigate('/register?hasPending=true')
    } catch (error) {
      toast.error('Failed to upload recording. Please try again.')
    } finally {
      setIsLoading(false)
      setShowModal(false)
    }
  }

  return (
    <div className='min-h-screen bg-gradient-to-b from-blue-50 to-white'>
      {/* Hero Section */}
      <div className='max-w-7xl mx-auto pt-16 px-4 sm:px-6 lg:px-8'>
        <div className='text-center'>
          <div className='flex justify-center mb-6'>
            <div className='p-3 bg-blue-100 rounded-full'>
              <HiSparkles className='h-8 w-8 text-blue-600' />
            </div>
          </div>
          <h1 className='text-4xl font-extrabold text-gray-900 sm:text-5xl md:text-6xl'>
            Try <span className='text-blue-600'>Reekap</span> Free
          </h1>
          <p className='mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl'>
            Record your meeting now. No sign-up required to start. Create your
            account after recording to get your AI-powered summary.
          </p>
        </div>
      </div>

      {/* Main Content */}
      <div className='max-w-4xl mx-auto mt-16 px-4 sm:px-6 lg:px-8'>
        <div className='bg-white rounded-2xl shadow-xl overflow-hidden'>
          {!isFinishedRecording ? (
            <div className='p-8'>
              {!isRecording ? (
                <div className='space-y-8'>
                  {/* Feature Grid */}
                  <div className='grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-2 mb-8'>
                    <div className='bg-blue-50 p-6 rounded-xl'>
                      <div className='flex items-center space-x-3'>
                        <FaMicrophone className='h-6 w-6 text-blue-600' />
                        <h3 className='text-lg font-semibold text-gray-900'>
                          High-Quality Audio
                        </h3>
                      </div>
                      <p className='mt-2 text-sm text-gray-600'>
                        Crystal clear voice recording optimized for meetings
                      </p>
                    </div>
                    <div className='bg-blue-50 p-6 rounded-xl'>
                      <div className='flex items-center space-x-3'>
                        <FaWaveSquare className='h-6 w-6 text-blue-600' />
                        <h3 className='text-lg font-semibold text-gray-900'>
                          30-Min Free Trial
                        </h3>
                      </div>
                      <p className='mt-2 text-sm text-gray-600'>
                        Record up to 30 minutes of content for free
                      </p>
                    </div>
                  </div>

                  {/* Start Recording Button */}
                  <div className='flex flex-col items-center justify-center space-y-4'>
                    <button
                      onClick={startRecording}
                      className='inline-flex items-center px-8 py-4 border border-transparent text-lg font-medium rounded-full text-white bg-blue-600 hover:bg-blue-700 transition-all transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                    >
                      <FaMicrophone className='mr-2' />
                      Start Recording
                    </button>
                    <p className='text-sm text-gray-500'>
                      Click to begin your recording session
                    </p>
                  </div>
                </div>
              ) : (
                <div className='space-y-6'>
                  <TrialRecordingTimer
                    recordingTime={recordingTime}
                    setRecordingTime={setRecordingTime}
                    isPaused={isPaused}
                    limitStats={trialLimitStats}
                    stopRecording={stopRecording}
                  />
                  <TrialRecordingControls
                    isPaused={isPaused}
                    onPause={pauseRecording}
                    onResume={resumeRecording}
                    onStop={stopRecording}
                  />
                </div>
              )}
            </div>
          ) : (
            <div className='p-8 text-center'>
              {isLoading && <LoadingSpinner />}
            </div>
          )}
        </div>
      </div>

      {/* Footer Info */}
      <div className='max-w-4xl mx-auto mt-12 px-4 sm:px-6 lg:px-8 mb-16'>
        <div className='bg-gradient-to-r from-blue-600 to-indigo-600 rounded-2xl shadow-xl p-8 text-white'>
          <h3 className='text-xl font-semibold mb-4'>What happens next?</h3>
          <div className='grid grid-cols-1 gap-6 sm:grid-cols-3'>
            <div>
              <div className='text-3xl font-bold mb-2'>1</div>
              <p>Record your meeting with our advanced audio capture system</p>
            </div>
            <div>
              <div className='text-3xl font-bold mb-2'>2</div>
              <p>Create your free account to unlock your AI-powered summary</p>
            </div>
            <div>
              <div className='text-3xl font-bold mb-2'>3</div>
              <p>
                Get instant access to your meeting transcription and insights
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      {showModal && (
        <Modal isOpen={showModal}>
          <div className='bg-white rounded-xl p-8 max-w-lg w-full'>
            <h3 className='text-2xl font-bold text-gray-900 mb-4'>
              Recording Complete!
            </h3>
            <p className='text-gray-600 mb-6'>
              Great job! Create your account now to access your AI-powered
              meeting summary.
            </p>
            <div className='space-y-4'>
              <button
                onClick={handleUploadAndRedirect}
                className='w-full bg-blue-600 text-white px-6 py-3 rounded-lg font-semibold hover:bg-blue-700 transition-all transform hover:scale-105'
              >
                Continue to Create Account
              </button>
              <button
                onClick={() => setShowModal(false)}
                className='w-full bg-gray-100 text-gray-700 px-6 py-3 rounded-lg font-semibold hover:bg-gray-200 transition-all'
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  )
}

export default TrialRecordingPage
