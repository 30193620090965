import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import PageLayout from '../components/PageLayout'
import UsageStatistics from '../components/UsageStatistics'
import QuickActions from '../components/QuickActions'
import PlanNotification from '../components/PlanNotification'
import NotificationModal from '../components/NotificationModal'

const DashboardPage = () => {
  const [userName, setUserName] = useState('')
  const [userPlan, setUserPlan] = useState('')
  const [remainingMinutes, setRemainingMinutes] = useState(0)
  const [showNotification, setShowNotification] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const navigate = useNavigate()
  const [nextResetDate, setNextResetDate] = useState('')
  const [pendingTranscription, setPendingTranscription] = useState(false)

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/user/me`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('reekapToken')}`,
            },
          }
        )
        const { name, subscription, usage } = res.data.data
        setUserName(name)
        setUserPlan(subscription.planId.name)

        // Convert allowedLimit and currentPeriodUsage from seconds to minutes
        const allowedMinutes =
          (subscription.planId.allowedLimit + usage.bonusLimit) / 60
        const usedMinutes = usage.currentPeriodUsage / 60

        // Calculate remaining minutes
        setRemainingMinutes(allowedMinutes - usedMinutes)

        // Check if the resetDate is valid before setting it
        if (usage.resetDate) {
          setNextResetDate(usage.resetDate)
        } else {
          console.warn('Received an empty or undefined resetDate from the API.')
        }
      } catch (err) {
        console.error('Error fetching user data:', err)
      }
    }

    const validatePendingTranscription = async (tempUserId) => {
      try {
        await axios.get(
          `${process.env.REACT_APP_API_URL}/api/trial/validate?id=${tempUserId}`
        )

        setPendingTranscription(true)
      } catch (error) {
        setPendingTranscription(false)
      }
    }

    fetchUserData()
    const tempUserId = localStorage.getItem('tempUserId')
    if (tempUserId) {
      validatePendingTranscription(tempUserId)
    }
  }, [])

  useEffect(() => {
    setIsModalOpen(true) // Open the modal when the dashboard loads
  }, [])

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const closeNotification = () => {
    setShowNotification(false)
  }

  return (
    <PageLayout userName={userName}>
      {showNotification && nextResetDate && (
        <PlanNotification
          userPlan={userPlan}
          remainingMinutes={remainingMinutes}
          closeNotification={closeNotification}
          resetDate={nextResetDate} // Pass the next reset date here
          onUpgradeClick={() => navigate('/plans')}
          pendingTranscription={pendingTranscription}
        />
      )}
      <section className='space-y-8 p-4 md:p-8'>
        <UsageStatistics />
        <QuickActions navigate={navigate} />
      </section>
      <NotificationModal isOpen={isModalOpen} onClose={handleCloseModal} />
    </PageLayout>
  )
}

export default DashboardPage
