import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link, Navigate } from 'react-router-dom'
import useAuth from '../hooks/useAuth'

const VerifyEmail = () => {
  const [otp, setOtp] = useState(new Array(6).fill(''))
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [message, setMessage] = useState('')
  const { user, setUser } = useAuth()
  const [isOpen, setIsOpen] = useState(false)
  const [scrolled, setScrolled] = useState(false)

  const API_BASE_URL = process.env.REACT_APP_API_URL

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50)
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const handleChange = (element, index) => {
    const value = element.value
    if (!isNaN(value) && value !== '') {
      const newOtp = [...otp]
      newOtp[index] = value

      // Update OTP state
      setOtp(newOtp)

      // Move focus to the next input
      if (index < otp.length - 1) {
        element.nextSibling && element.nextSibling.focus()
      }
    }
  }

  const handlePaste = (e) => {
    e.preventDefault()
    const pasteData = e.clipboardData.getData('text').slice(0, 6).split('')
    if (pasteData.every((char) => !isNaN(char))) {
      setOtp([...pasteData, ...new Array(6 - pasteData.length).fill('')])
    }
  }

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace') {
      if (otp[index] === '') {
        if (index > 0) {
          e.target.previousSibling && e.target.previousSibling.focus()
        }
      } else {
        const newOtp = [...otp]
        newOtp[index] = ''
        setOtp(newOtp)
      }
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    setError('')
    try {
      setIsLoading(true)
      const otpCode = otp.join('')
      const response = await axios.post(
        `${API_BASE_URL}/api/auth/verify-email`,
        { userId: user?.id, otp: otpCode }
      )
      if (response.data && response.status === 200) {
        setMessage(response.data.message)
        setUser((prevUser) => ({ ...prevUser, isEmailVerified: true }))
      } else {
        throw new Error('Verification response issue')
      }
      setIsLoading(false)
    } catch (err) {
      console.error('Error during email verification:', err)
      setError(err.response?.data?.message || 'Failed to verify email.')
      setMessage('')
      setIsLoading(false)
    }
  }

  // if (user?.id && user?.isEmailVerified) {
  //   return <Navigate to='/verify-phone' />
  // }

  if (user?.id && user?.isEmailVerified) {
    return <Navigate to='/dashboard' />
  }

  if (!user?.id && user?.isEmailVerified !== false) {
    return <Navigate to='/register' />
  }

  return (
    <div className='bg-gray-100 min-h-screen'>
      {/* Navigation Menu */}
      <div
        className={`fixed top-0 left-0 w-full z-50 py-0 transition-colors duration-300 ${
          scrolled ? 'bg-[#2563EB]' : 'bg-transparent'
        } backdrop-blur`}
      >
        <div className='max-w-7xl mx-auto flex items-center justify-between px-4 py-4'>
          <Link to='/'>
            <img
              src='/assets/images/footer_logo.png'
              alt='Reekap Logo'
              className='w-24 md:w-32'
            />
          </Link>

          <div className='md:hidden z-50'>
            <button
              onClick={toggleMenu}
              className={`${
                scrolled ? 'text-white' : 'text-[#2563EB]'
              } focus:outline-none`}
              aria-label='Toggle menu'
            >
              {isOpen ? (
                <svg
                  className='w-6 h-6'
                  fill='none'
                  stroke='currentColor'
                  viewBox='0 0 24 24'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M6 18L18 6M6 6l12 12'
                  />
                </svg>
              ) : (
                <svg
                  className='w-6 h-6'
                  fill='none'
                  stroke='currentColor'
                  viewBox='0 0 24 24'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M4 6h16M4 12h16m-7 6h7'
                  />
                </svg>
              )}
            </button>
          </div>

          <div
            className={`${
              isOpen ? 'flex' : 'hidden'
            } md:flex flex-col md:flex-row items-center md:items-center absolute md:relative top-16 md:top-0 left-0 w-full md:w-auto bg-[#2c3e50] md:bg-transparent z-40 py-6 md:py-0 md:space-x-8`}
          >
            <Link
              to='/pricing'
              className={`${
                scrolled ? 'text-white' : 'text-[#2563EB]'
              } font-bold text-base md:text-lg py-2 px-4 md:p-0 hover:text-[#38b6ff] transition-colors duration-200`}
            >
              Pricing
            </Link>
            <Link
              to='/faqs'
              className={`${
                scrolled ? 'text-white' : 'text-[#2563EB]'
              } font-bold text-base md:text-lg py-2 px-4 md:p-0 hover:text-[#38b6ff] transition-colors duration-200`}
            >
              FAQs
            </Link>
            <Link
              to='/login'
              className={`${
                scrolled ? 'text-white' : 'text-[#2563EB]'
              } font-medium text-base md:text-lg py-2 px-4 md:p-0 hover:text-[#38b6ff] transition-colors duration-200`}
            >
              Login
            </Link>
            <Link
              to='/register'
              className={`${
                scrolled ? 'bg-white text-[#2563EB]' : 'bg-[#2563EB] text-white'
              } py-2 px-4 rounded-lg font-semibold text-base md:text-lg hover:bg-white hover:text-[#2563EB] transition-colors duration-300 md:ml-4`}
            >
              Start for free
            </Link>
          </div>
        </div>
      </div>

      <div className='flex h-[100vh] items-center justify-center bg-gray-100 w-full px-4 md:px-0'>
        <div className='bg-white border shadow-lg rounded-lg flex flex-col items-center w-full md:w-1/2 lg:w-1/3 p-4 md:p-8'>
          <h1 className='text-2xl md:text-3xl font-bold mb-4 md:mb-6 text-center'>
            Verify Email
          </h1>
          <p className='mb-4 md:mb-6 text-center'>
            Please enter the OTP sent to your email to verify.
          </p>
          {error && <div className='text-red-600 text-center'>{error}</div>}
          {message && (
            <div className='text-green-600 text-center'>{message}</div>
          )}
          <form
            onSubmit={onSubmit}
            className='w-full flex flex-col items-center'
          >
            <div className='flex justify-center mb-4 md:mb-8 space-x-2'>
              {otp.map((data, index) => (
                <input
                  className='w-8 md:w-10 h-8 md:h-10 text-center border-2 border-blue-500 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500'
                  type='text'
                  name='otp'
                  maxLength='1'
                  key={index}
                  value={data}
                  onChange={(e) => handleChange(e.target, index)}
                  onFocus={(e) => e.target.select()}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  onPaste={(e) => handlePaste(e)}
                  required
                />
              ))}
            </div>
            <button
              type='submit'
              className='mt-4 md:mt-6 bg-blue-500 text-white py-2 px-4 rounded-md w-full md:w-auto hover:bg-blue-600 transition-colors duration-200 ease-in-out'
              disabled={isLoading}
            >
              {isLoading ? <div className='loading'></div> : 'Verify Email'}
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default VerifyEmail
